require("packs/jquery_validator");

document.addEventListener("DOMContentLoaded", () => {
    function applyValidation(formId, type) {
        $(formId).validate({
            errorElement: "span",
            errorClass: "error_star",
            rules: {
                "user[login]": {
                    required: true,
                    minlength: 3,
                    maxlength: 30
                },
                "user[email]": {
                    required: true,
                    email: true
                },
                "user[password]": {
                    required: true,
                    minlength: 8
                },
                "user[password_confirmation]": {
                    required: true,
                    equalTo: type === "page" ? "#user_password_page" : "#user_password_modal"
                },
                
            },
            highlight: function(element) {
                $(element).addClass("error_class");
            },
            unhighlight: function(element) {
                $(element).removeClass("error_class");
            },
            messages: {
                "user[login]": {
                    required: `${I18n.t('user_name_required')}.`,
                    minlength: `${I18n.t('username_length_validation')}.`,
                    maxlength: `${I18n.t('username_max_length')}.`
                },
                "user[email]": {
                    required: `${I18n.t('email_required')}.`,
                    email: `${I18n.t('email_invalid')}.`
                },
                "user[password]": {
                    required: `${I18n.t('password_required')}.`,
                    minlength: `${I18n.t('password_length')}.`
                },
                "user[password_confirmation]": {
                    required: `${I18n.t('password_confirmation')}.`,
                    equalTo: `${I18n.t('password_not_match')}`
                }
            }
        });
    
        // Username validation on keyup
        $(`${formId} input[name="user[login]"]`).on('keyup', function() {
            let username = $(this).val().trim();
            let target = `${formId} #sign_up_form_error_login`;
            if(validateUserNameFirst(username))
              {
                $(target).html("User Name can contain letters (A-Z, a-z), numbers (0-9), hyphen (-), underscore (_), and period (.)").show();
              }
            else if(validateUserNameDomain(username))
              {
                $(target).html("User Name should not end with .com .in .net .org").show();
              }
            else
              {
                if(username.length >= 3 || username.length <= 30) validateUserName(username, target);
              }
        });

        $(`${formId} input[name="user[email]"]`).on('keyup', function() {
            let email = $(this).val().toLowerCase().trim();
            let target = `${formId} #sign_up_form_error_email`;

            if(validateEmailFirst(email)){
              validateEmail(email, target);
            } 
        });
    }
    
    // Apply the validation to both forms
    applyValidation("#sign_up_form_page", "page");
    applyValidation("#sign_up_form_modal", "modal");

    $("#forgot-password-form").validate({
        errorElement: "span",
        errorClass: "error_star",
        rules: {
            "email": {
                required: true,
                email: true
            }
        },
        highlight: function(element) {
            $(element).addClass("error_class");
        },
        unhighlight: function(element) {
            $(element).removeClass("error_class");
        },
        messages: {
            "email": {
                required: `${I18n.t('email_required')}.`,
                email: `${I18n.t('email_invalid')}.`
            }
        }
    })

    // Reset password validation for password field
    $(".edit_user").validate({
      errorElement: "span",
      errorClass: "error_star",
      rules: {
        "user[password]": {
            required: true,
            minlength: 8
        },
        "user[password_confirmation]": {
            required: true,
            equalTo: "input[name='user[password]']"
        },
        
    },
      highlight: function(element) {
          $(element).addClass("error_class");
      },
      unhighlight: function(element) {
          $(element).removeClass("error_class");
      },
      messages: {
        "user[password]": {
            required: `${I18n.t('password_required')}.`,
            minlength: `${I18n.t('password_length')}.`
        },
        "user[password_confirmation]": {
            required: `${I18n.t('password_confirmation')}.`,
            equalTo: `${I18n.t('password_not_match')}`
        }
    }
  })
});




function validateUserName(username, target){
    $.ajax({
        url: "/profile",
        dataType: "script",
        type: "get",
        data: {username: username} ,
        success: function(data){
            if(data == "true"){
                $(target).html(`${I18n.t('username_exist')}.`).hide().fadeIn(500);
            }
            else{
                $(target).fadeOut();
            }
        }
    });
}

function validateEmail(email, target){
    $.ajax({
        url: "/profile",
        dataType: "script",
        type: "get",
        data: {email: email} ,
        success: function(data){
            if(data == 'true') {
                $(target).html(`${I18n.t('email_exist')}.`).hide().fadeIn(500);
            }

            else {
                $(target).fadeOut();
            }

        }
    });
}

function validateEmailFirst(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}


function validateUserNameFirst(str) {
    var re = /[^A-Za-z0-9\.\_\-S]/;
    return re.test(str);
}

function validateUserNameDomain(str) {
    var str = str.split('.');
    if(['com','in','net','org'].indexOf(str[str.length-1]) !== -1){
        return true;
    }else{
        return false;
    }
}
