// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

// I18n-js integration
import I18n from 'i18n-js'
window.I18n = I18n
I18n.locale = 'ru';

// JavaScript does not load until the page is explicitly reloaded via the URL.
// import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application";
import 'stylesheets/redactor-custom.css';
import jQuery from 'jquery';

window.jQuery = window.$ = jQuery; // this needs to run before
window.Cookies = require('js-cookie')

Rails.start()
// Turbolinks.start()

require("packs/link_tracker");
require("packs/common");
require("packs/voteup_votedown");
require("packs/threaded_comments");
require("packs/signup_validation");


document.addEventListener('focus', (event) => {
    const replyBox = event.target;

    if (replyBox.id === 'reply_box' && replyBox.dataset.notLoggedin === "true") {
        document.querySelector('.redirect_url').value = replyBox.dataset.redirectUrl;
    }
}, true);

ActiveStorage.start()
