$(document).on("click", '.topic_vote_up', function(){
  var topic_link=$(this).data("tlink");
  var forum_type=$(this).data("ftype");
  $(".topic_vote").fadeTo("slow", 0.33, function () {
    $(this).css("cursor", "");
  })
  $.ajax({url: '/'+ forum_type +'/'+ topic_link +'/voteup',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    type: 'POST',
    dataType: 'script'
  })
  return false;
});

$(document).on("click", '.topic_vote_down', function(){
  var topic_link=$(this).data("tlink");
  var forum_type=$(this).data("ftype");
  $(".topic_vote").fadeTo("slow", 0.33, function () {
   $(this).css("cursor", "");
  })
  $.ajax({url: '/'+ forum_type +'/'+topic_link +'/votedown',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    },
    type: 'POST',
    dataType: 'script'
  })
  return false;
});
