$(document).on("click", '.moreReplies', function () {
    var currentPage = 0;
    currentPage = $(this).parent().data("currentPage") || 0;
    currentPage++;
    $(this).parent().data("currentPage", currentPage);
    var parentId = $(this).attr('id');
    const urlParams = new URLSearchParams(window.location.search);
    const sortBy = urlParams.get('sort_by');
    $.ajax({
        url: "/child_comments_by_parent?post_id=" + parentId + "&sub_page=" + currentPage + "&per_page=10" + "&sort_by=" + sortBy,
        dataType: "script",
        type: "GET",
        headers: {
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
        success: function (r) {
        }
    });
});

setTimeout(function () {
    $('.deleteSection').animate({
        "height": "0px",
        "opacity": "0"
    }, function () {
        setTimeout(function () {
            $('.deleteSection').remove();
        }, 1000)
    });

}, 4000);

var commentUrl = window.location.href;
var pieces = commentUrl.split("#");

function highlight() {
    $("#" + pieces[1]).addClass("highlight");
    setTimeout(function () {
        $("#" + pieces[1]).removeClass("highlight");
    }, 3000);
}

$(document).ready(function () {
    setTimeout(function () {
        var offset = $("#" + pieces[1]).offset();
        if (offset != undefined) {
            $('html, body').animate({
                scrollTop: offset.top - 100,
                scrollLeft: offset.left
            }, 1000);
            highlight();
        }
    }, 2000);
});